<template>
  <div id="login-div">
    <div class="title-holder">
      <span> Fluke </span>
      {{ $t("pages.login.title") }} <br />
    </div>
    <div class="login-card" v-if="!$store.state.auth.user.acceptance">
      {{ $t("pages.setPassword.disclaimer") }}<br />
      {{ $t("pages.setPassword.title") }}<br />
      <br />
      <v-form v-model="isValid" ref="form">
        <v-text-field
          outlined
          :label="$t('labels.formNewPassword')"
          type="password"
          :rules="[requiredRule]"
          v-model="auth.password"
        />
        <v-text-field
          type="password"
          outlined
          :rules="[requiredRule, mustMatch]"
          :label="$t('labels.formConfirmPassword')"
          v-model="auth.rePass"
        />
      </v-form>
    </div>
    <div class="login-card">
      <div>
        {{ $t("pages.setPassword.disclaimerCompliance") }}
        <br />
        <br />
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on">{{
              $t("pages.setPassword.openCompliance")
            }}</v-btn>
          </template>
          <v-card>
            <v-card-title class="text-center text-h6 grey lighten-2">{{
              $t("pages.setPassword.titleCompliance")
            }}</v-card-title>
            <v-card-text>
              <div v-html="$t('pages.setPassword.termResponsibility')" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ $t("labels.btnClose") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <br />
        <br />
        <v-checkbox
          v-model="auth.acceptance"
          :label="$t('pages.setPassword.checkTerms')"
        ></v-checkbox>
      </div>
    </div>
    <div class="btn-aligner">
      <div class="btns-aux"></div>
      <div class="btns-main">
        <v-btn
          large
          depressed
          :disabled="!auth.acceptance"
          block
          color="primary"
          @click="login"
        >
          {{ $t("labels.btnLogin") }}
        </v-btn>
      </div>
    </div>

    <f-language-selector />
  </div>
</template>

<script>
import validatorRules from "../../helpers/validator_rules";
import FLanguageSelector from "./LanguageSelector.vue";

export default {
  components: {
    FLanguageSelector,
  },
  data: () => ({
    auth: {
      password: "",
      rePass: "",
      id: null,
      acceptance: false,
    },
    isValid: false,
    dialog: false,
  }),
  computed: {
    requiredRule() {
      return validatorRules.required(this.$t("validation.required"));
    },
    mustMatch() {
      return validatorRules.confirmPassword(
        this.auth.password,
        this.$t("validation.confirmPassword")
      );
    },
  },
  created() {
    this.auth.acceptance = this.$store.state.auth.user.acceptance
      ? true
      : false;
  },
  methods: {
    async login() {
      this.$refs.form.validate();
      if (!this.isValid) return false;

      this.auth.id = this.$store.state.auth.user.id;
      const response = await this.$store.dispatch(
        "auth/setPassword",
        this.auth
      );
      if (response == false) {
        alert("fail");
      } else {
        this.$router.push({
          name: "user-list",
        });
      }
    },
    openResponsabilityModal() {
      console.log("openResponsabilityModal");
    },
  },
};
</script>

<style lang="scss">
#login-div {
  width: 480px;
  margin-bottom: 100px;

  .login-card {
    background-color: #fff;
    padding: 40px 40px 10px 40px;
    border-radius: 3px;
    margin-bottom: 30px;
  }

  .title-holder {
    padding: 0 40px;
    margin-bottom: 30px;

    span {
      display: block;
      font-weight: 700;
      font-size: 32px;
      margin-bottom: -10px;
    }
  }

  .btn-aligner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;

    .btns-main {
      button {
        width: 150px;
      }
    }
    .btns-aux {
      a {
        text-decoration: none;
        color: #a9a9a9;
      }
    }
  }

  .err {
    background-color: #ff5b5b;
    color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    max-height: 0;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      max-height: 100px;
      padding: 10px 15px;
      opacity: 1;
    }
  }

  .forgot {
    margin-top: 35px;
    font-size: 13px;
    cursor: pointer;
  }
}
</style>
